import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';

interface Props {
  text?: string;
}

function TeamMarkdown(props: Props) {
  return (
    <ReactMarkdown
      components={{
        p: (props) => <p className="text-h4 text-white">{props.children}</p>,
        strong: (props) => (
          <strong className="text-h4 text-accent">{props.children}</strong>
        ),
      }}
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
      children={props.text}
    />
  );
}

export default TeamMarkdown;
